// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-page-js": () => import("./../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-js": () => import("./../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-templates-search-js": () => import("./../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-tag-js": () => import("./../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

